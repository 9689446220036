import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import CompanyOwnerSelect from '../../../../components/CompanyOwnerSelect';
import CustomButton from '../../../../components/layout/CustomButton';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../components/layout/Sidedrawer';
import { customSelectDropDown } from '../../../../components/styled/customSelect';
import { AppContext } from '../../../../context';
import { JobAppStatusEnum, workplaceTypesDto } from '../../../../utils/constant';

import {
  APPLY_TO_JOB,
  FIND_OR_CREATE_JOBAPPLICANT,
} from '../../../job-order/gql';
import { GET_JOB_ORDERS_FOR_COMPANY } from '../../../recruiter-folder/gql';
import SubmissionDetailDrawer from './SubmissionDetailDrawer';
import { CREATE_SUBMISSION } from '../../../candidate-submission/gql';

const CandidateSubmitDrawer = ({
  companyId,
  jobOrderId,
  recruiterFolderApplicantId,
  recruiterFolderSubmissionId,
  toggle, refetchOwner,
  isOpen,
  candidate,
  applyJob,
  refetch,
}: any) => {
  const { theme } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [applicantId, setApplicantId] = useState<string>('');
  const [submissionId, setSubmissionId] = useState<string>('');
  const [submissionDetailToggle, setSubmissionDetailToggle] =
    useState<boolean>(!!(companyId && jobOrderId && recruiterFolderApplicantId && recruiterFolderSubmissionId));
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>();
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [jobOrderOptions, setJobOrderOptions] = useState<Array<any>>([]);
  const [createJobApplicantSubmitted, { loading }] = useMutation(
    FIND_OR_CREATE_JOBAPPLICANT
  );
  const [fetchAllJobOrders, { loading: waiting, data }] = useLazyQuery(
    GET_JOB_ORDERS_FOR_COMPANY
  );

  const [submissionCreate, { loading: submitting }] =
    useMutation(CREATE_SUBMISSION);

  const [onApplyMutation] = useMutation(APPLY_TO_JOB);

  useEffect(() => {
    if (selectedCompany) {
      fetchAllJobOrders({
        variables: { companyId: selectedCompany, limit: 50, page: 1 },
      });
    }

    // eslint-disable-next-line
  }, [selectedCompany]);
  // this function is used for the purpose of the checking if the candidate is submitted
  const onSubmitHandler = async () => {
    let jobOrderId = selectedJob?.value?.jobOrderId;
    if (applyJob) {
      await onApplyHandler();
      refetch();
      toggle();
    } else {
      let res = await createJobApplicantSubmitted({
        variables: { candidateId: candidate?.id, jobOrderId: jobOrderId },
      });
      let jobStatus = res?.data?.findOrCreateJobApplicant?.JobAppStatus;

      if (jobStatus === 'CLIENT_DECLINE' || jobStatus === 'CANDIDATE_DECLINE') {
        toast.info(`Candidate Already ${JobAppStatusEnum[jobStatus]} `);
        return;
      }
      if (res?.data?.findOrCreateJobApplicant) {
        setApplicantId(res?.data?.findOrCreateJobApplicant?.id);
        createSubmission(res?.data?.findOrCreateJobApplicant?.id) // just creating a submission with necessary info to get the submission Id
      }
    }
  };

  // this function is written because when we aad attachments in the submission detail, 
  // submission id is required to save that attachment hence created a submission with the necessary info 
  const createSubmission = async (id) => {
    if (id) {
      let res = await submissionCreate({
        variables: {
          createSubmission: {
            applicantId: id,
            candidateId: candidate?.id,
            jobOrderId: selectedJob?.value?.jobOrderId,
          },
        },
      });
      if (res?.data) {
        setSubmissionDetailToggle(!submissionDetailToggle);
        setSubmissionId(res?.data?.createSubmission?.submission?.id)
      }
      else {
        toast.error('Error While Creating Submission!');
      }
    };
  }
  // this fuction is used to apply the job to the candidate
  const onApplyHandler = async () => {
    let jobOrderId = selectedJob?.value?.jobOrderId;
    let res = await onApplyMutation({
      variables: { candidateId: candidate?.id, jobOrderId: jobOrderId },
    });

    if (res?.data?.createSubmissionOnApply?.status === 201) {
      toast.error(
        `Job applicant already exists with status ${res?.data?.createSubmissionOnApply?.jobApplicant?.JobAppStatus}`
      );
    } else {
      toast.success(
        `Job applicant applied and status changed to Applied with source Recruiter`
      );
    }
    toggle();
  };

  // here we create the job order option according to the company select
  const createJobOrderOption = () => {
    let jobs = data?.findJobOrdersForCompany?.jobOrders;
    if (jobs?.length) {
      let options = jobs?.map((item) => ({
        label: `${item.jobTitle} ${['US', null, undefined, ''].includes(item?.companyLocation?.country) ? item?.companyLocation?.city ? `- ${item?.companyLocation?.city + ', ' + item?.companyLocation?.state}` : `- ${item?.companyLocation?.state}` : item?.companyLocation?.city ? `- ${item?.companyLocation?.city + ', ' + item?.companyLocation?.country}` : `- ${item?.companyLocation?.country}`}`,
        value: {
          jobOrderId: item?.id,
          allowRemote: `${item?.allowRemote}`,
          companyOwner: item?.company?.companyOwner?.owner?.fullName,
          status: item?.status,
          workplaceTypes: item?.workplaceTypes,
          location: `${['US', null, undefined, ''].includes(item?.companyLocation?.country) ? item?.companyLocation?.city ? item?.companyLocation?.city + ', ' + item?.companyLocation?.state : item?.companyLocation?.state : item?.companyLocation?.city ? item?.companyLocation?.city + ', ' + item?.companyLocation?.country : item?.companyLocation?.country}`,
        },
      }));
      setJobOrderOptions(options);
    } else {
      setJobOrderOptions([{ label: '', value: '' }]);
    }
  };

  useEffect(() => {
    if (data?.findJobOrdersForCompany?.jobOrders) {
      createJobOrderOption();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.findJobOrdersForCompany?.jobOrders, selectedCompany]);
  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen}>
        <div className="pb-4">
          <DrawerHeader
            prevClick={toggle}
            nextClick={applyJob ? onApplyHandler : onSubmitHandler}
            heading="Candidate Submission"
            disabled={!(selectedJob && selectedCompany)}
            buttonText={`${applyJob ? 'Apply' : 'Next'}`}
            loading={loading || submitting}
            nextButtonView={false}
          />
        </div>
        <div>
          <h6 className='dark-theme-text'>Job Order</h6>
          <Row className="">
            <Col xs="12">
              <CompanyOwnerSelect
                onChange={(data) => {
                  setSelectedCompany(data?.value?.companyId);
                  setSelectedCompanyName(data?.label);
                  setSelectedJob(null);
                }}
              />
            </Col>
            <Col xs="12" className="pt-4">
              <Select
                isSearchable
                isLoading={waiting}
                placeholder="Select Job"
                name="jobs"
                isDisabled={
                  data?.findJobOrdersForCompany?.jobOrders?.length
                    ? false
                    : true
                }
                options={jobOrderOptions}
                value={selectedJob}
                className="basic-multi-select mb-0"
                classNamePrefix="select"
                styles={{ ...customSelectDropDown(theme) }}
                onChange={(item: any) => {
                  setSelectedJob(item);
                }}
              />

              {!waiting && (
                <>
                  {!data?.findJobOrdersForCompany?.jobOrders?.length &&
                    selectedCompany ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: 12, marginTop: 0 }}
                    >
                      No active job order found for {selectedCompanyName}
                    </span>
                  ) : null}
                </>
              )}
            </Col>
          </Row>
          <div className="text-right mt-3">
            <CustomButton
              disabled={!(selectedJob && selectedCompany)}
              buttonText={`${applyJob ? 'Apply' : 'Next'}`}
              loading={loading}
              buttonColor="primary"
              buttonType="submit"
              // className="big-width"
              buttonClick={applyJob ? onApplyHandler : onSubmitHandler}
            />
          </div>
          {selectedCompany && selectedJob && (
            <div className="mt-4">
              <Row>
                <Col>
                  <h6 className="text-14 text-blackele mb-2 dark-theme-text">Company Owner</h6>
                </Col>
                <Col>
                  <h6 className="font-weight-normal text-14 text-blackten mb-2 dark-theme-text">
                    {selectedJob?.value?.companyOwner || '--'}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="text-14 text-blackele mb-2 dark-theme-text">Job Location</h6>
                </Col>
                <Col>
                  <h6 className="font-weight-normal text-14 text-blackten mb-2 dark-theme-text">
                    {selectedJob?.value?.location || '--'}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="text-14 text-blackele mb-2 dark-theme-text">Workplace Type</h6>
                </Col>
                <Col>
                  <h6 className="font-weight-normal text-14 text-blackten mb-2 dark-theme-text">
                    {selectedJob?.value?.workplaceTypes ? workplaceTypesDto[selectedJob.value.workplaceTypes] : '--'}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="text-14 text-blackele mb-2 dark-theme-text">Job Status</h6>
                </Col>
                <Col>
                  <h6 className="font-weight-normal text-14 text-blackten mb-2 dark-theme-text">
                    {selectedJob?.value?.status || '--'}
                  </h6>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Sidedrawer>
      {submissionDetailToggle && (
        <SubmissionDetailDrawer
          setCandidateFilter={() => {
            history.push(
              `${location?.pathname}?activeTab=8&candidateFilter=Submitted`
            );
          }}
          refetchOwner={refetchOwner}
          toggle={() => setSubmissionDetailToggle(!submissionDetailToggle)}
          isOpen={submissionDetailToggle}
          jobOrderId={jobOrderId || selectedJob?.value?.jobOrderId}
          candidate={candidate}
          applicantId={applicantId || recruiterFolderApplicantId}
          submissionId={submissionId || recruiterFolderSubmissionId}
          companyId={companyId || selectedCompany}
          backClick={() => {
            setSubmissionDetailToggle(false);
            // toggle();
          }}
          refetchApplicant={() => {
            refetch();
            toggle();
          }}
        />
      )}
    </>
  );
};

export default CandidateSubmitDrawer;
