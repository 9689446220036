// packages block
import { forwardRef, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
// graphql block
import { AppContext } from '../context';
import { SelectorOptionType } from '../interfaces';
import closeIcon from '../assets/images/close.svg';
import barIcon from '../assets/images/edit-info.svg';
import { Controller, useForm } from 'react-hook-form';
import { CREATE_NOTES, UPDATE_NOTE } from '../Pages/company/gql';
import {
  candidateNotesCategories,
  companyNotesCategories,
  contactNotesCategories,
  interviewDetailsNotesCategories,
  jobApplicantNotesCategories,
  jobNotesCategories,
  offerCategoryNotes,
  reactQuillReg,
  submissionNoteCategories,
} from '../utils/constant';
import { filterNoteCategories } from '../utils/helper';
import { ParentRef } from '../Pages/agreements/interfaces';

const AddNotesModal = forwardRef<ParentRef, any>(({
  noteableId,
  activityNotes,
  toggleActivityNotes,
  reFetch,
  type,
  editData,
  refetchCandidate,
  haveActionPersmissions,
  resetField,
  updateNotesRef,
  ...props
}, ref) => {
  const { user, userRoles, theme } = useContext(AppContext);
  const [category, setCategory] = useState<SelectorOptionType | null>();
  const [notesValue, setNotesValue] = useState<string>('');

  const {
    errors,
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    setError,
  } = useForm();

  const [_createNotes, { loading: createLoading }] = useMutation(CREATE_NOTES);
  const [_updateNotes, { loading }] = useMutation(UPDATE_NOTE);
  const toggle = () => toggleActivityNotes && toggleActivityNotes();

  const updateLocalStorage = async () => {
    const notesInStorage = localStorage.getItem('notes');
    let localNotes = !!notesInStorage ? JSON.parse(notesInStorage) : [];
    localNotes = Array.isArray(localNotes) ? localNotes : [localNotes];
    let currentCandidateNote = localNotes.filter(
      (note) =>
        note?.URL === window.location.pathname && note?.user === user?.id
    )[0];
    const index = currentCandidateNote
      ? localNotes.indexOf(currentCandidateNote)
      : -1;

    if (currentCandidateNote) {
      localNotes.splice(index, 1);
      setCategory(null);
      setNotesValue('');
      setValue('category', null);
      setValue('content', null);
      localStorage.setItem('notes', JSON.stringify(localNotes));
    }
  };

  const createNotes = async (values) => {
    let payload = {
      noteableType: type,
      noteableId: noteableId,
      content: values?.content,
      category: getValues()?.isCompenstaionNote
        ? 'Candidate Compensation'
        : values?.category?.value,
      isCompenstaionNote: values?.isCompenstaionNote,
    };
    if (!payload.category) {
      return setError('category', { message: 'Category must be selected!' });
    }
    const res = await _createNotes({ variables: { ...payload } });
    if (res?.errors) {
      toggle();
      toast.error(res?.errors[0]?.message);
    }
    if (res?.data) {
      refetchCandidate && refetchCandidate()
      toggle();
      if (getValues()?.isCompenstaionNote) {
        props?.setCompensationNote(true);
      }
      reFetch(values?.category?.value);
      await updateLocalStorage();
      updateNotesRef?.current?.updateNotes()
      reset();
      toast.success('Successfully created!');
    }
  };
  const updateNote = async (values) => {
    let payload = {
      noteId: editData?.id,
      content: values?.content,
      category: values?.category?.value,
    };
    let res = await _updateNotes({
      variables: { notePayload: { ...payload } },
    });
    if (res?.data) {
      toast.success('Successfully updated!');
      toggle();
      reFetch();
      const notesInStorage = localStorage.getItem('notes');
      let localNotes = !!notesInStorage ? JSON.parse(notesInStorage) : [];
      localNotes = Array.isArray(localNotes) ? localNotes : [localNotes];
      let currentCandidateNote = localNotes.filter(
        (note) =>
          note?.URL === window.location.pathname && note?.user === user?.id
      )[0];
      const index = currentCandidateNote
        ? localNotes.indexOf(currentCandidateNote)
        : -1;

      if (currentCandidateNote) {
        localNotes.splice(index, 1);
        setCategory(null);
        setNotesValue('');
        setValue('category', null);
        setValue('content', null);
        localStorage.setItem('notes', JSON.stringify(localNotes));
      }
    }
  };
  const storeNotes = (value) => {
    const notesInStorage = localStorage.getItem('notes');
    let localNotes = !!notesInStorage ? JSON.parse(notesInStorage) : [];
    localNotes = Array.isArray(localNotes) ? localNotes : [localNotes];
    let currentCandidateNote = localNotes.filter(
      (note) =>
        note?.URL === window.location.pathname && note?.user === user?.id
    )[0];
    const index = !!currentCandidateNote
      ? localNotes.indexOf(currentCandidateNote)
      : -1;

    const note = {
      user: user?.id,
      category: category?.value,
      URL: window.location.pathname,
      notes: value || '',
    };

    if (value !== '<p><br></p>') {
      if (currentCandidateNote) {
        if (index > -1) {
          localNotes[index] = note;
        }
      } else {
        localNotes.push(note);
      }
    } else {
      if (currentCandidateNote) {
        localNotes.splice(index, 1);
      }
    }

    localStorage.setItem('notes', JSON.stringify(localNotes));
  };

  useEffect(() => {
    if (!editData?.id && activityNotes) {
      const localNotes = localStorage.getItem('notes');
      let locallyNotes = localNotes ? JSON.parse(localNotes) : [];
      locallyNotes = Array.isArray(locallyNotes) ? locallyNotes : [locallyNotes];
      let currentCandidateNote = locallyNotes.filter((note) => note?.URL === window.location.pathname && note?.user === user?.id)[0];
      if (currentCandidateNote) {
        const category = currentCandidateNote?.category;
        setValue('category', { value: category, label: category });
        setCategory({ value: category, label: category });
        setValue('content', currentCandidateNote?.notes || '');
        setNotesValue(currentCandidateNote?.notes || '');
      } else {
        setCategory(null);
        setValue('category', null);
        setValue('content', null);
        setNotesValue('');
      }

    }
  }, [activityNotes, editData?.id, setValue, user?.id]);

  useEffect(() => {
    if (!editData?.id && category?.value) {
      const localNotes = localStorage.getItem('notes');
      let locallyNotes = localNotes ? JSON.parse(localNotes) : [];
      locallyNotes = Array.isArray(locallyNotes)
        ? locallyNotes
        : [locallyNotes];
      let currentCandidateNote = locallyNotes.filter(
        (note) =>
          note?.URL === window.location.pathname && note?.user === user?.id
      )[0];
      const index = locallyNotes.indexOf(currentCandidateNote);

      const note = {
        user: user?.id,
        category: category?.value
          ? category?.value
          : currentCandidateNote?.category,
        URL: window.location.pathname,
        notes: currentCandidateNote?.notes,
      };

      if (!!currentCandidateNote) {
        if (index > -1) {
          locallyNotes[index] = note;
          setValue('content', currentCandidateNote?.notes || '');
          setNotesValue(currentCandidateNote?.notes || '');
          localStorage.setItem('notes', JSON.stringify(locallyNotes));
        }
      }
    } else {
      setNotesValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.value]);

  useEffect(() => {
    if (editData?.id) {
      let values = { ...editData };

      if (values?.noteableType === 'Job Order') {
        values.category = jobNotesCategories?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Candidate') {
        let foundOne = candidateNotesCategories?.find(
          (item) => item?.value === values?.category
        );

        if (foundOne) {
          values.category = candidateNotesCategories?.find(
            (item) => item?.value === values?.category
          );
        } else {
          values.category = {
            label: values?.category,
            value: values?.category,
          };
        }
      }
      if (values?.noteableType === 'Contact') {
        values.category = contactNotesCategories?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Company') {
        values.category = companyNotesCategories?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Interview') {
        values.category = interviewDetailsNotesCategories?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Offer') {
        values.category = offerCategoryNotes?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Submission') {
        values.category = submissionNoteCategories?.find(
          (item) => item?.value === values?.category
        );
      }
      if (values?.noteableType === 'Job Applicant') {
        values.category = jobApplicantNotesCategories?.find(
          (item) => item?.value === values?.category
        );
      }

      reset({ ...values });
      setCategory(values?.category);
      !activityNotes && toggle();
    }
    // eslint-disable-next-line
  }, [editData?.id]);

  useEffect(() => {
    if (!activityNotes) {
      reset({});
      resetField(null);
    }
    // eslint-disable-next-line
  }, [activityNotes]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const customStylesSelect = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: props?.zIndex || 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div className="position-relative">
      {activityNotes && (
        <Card className="agreement-details activity-card mt-0 client-add-notes">
          <div className="section-header-add-client-notes">
            <h5 className="m-0">{type || 'Create'} Notes</h5>
            <Button
              type="button"
              onClick={toggle}
              className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
            >
              <img src={closeIcon} className="m-0" alt="" width="13" />
            </Button>
          </div>

          <CardBody>
            <Form>
              <FormGroup>
                <div className="mobileResponsiveFlexDevice">
                  <Label>
                    Category{' '}
                    {!getValues()?.isCompenstaionNote && (
                      <span className="text-danger">*</span>
                    )}
                  </Label>
                  {userRoles?.includes('EXECUTIVE') &&
                    type === 'Candidate' &&
                    props?.isPrivateCandidate && (
                      <div className="d-flex align-items-center justify-content-center w-auto">
                        <Label>Compensation Notes</Label>
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            name="isCompenstaionNote"
                            innerRef={register()}
                            onChange={({ target: { checked } }) => {
                              setCategory({ label: '', value: '' });
                              props?.setCompensationNote(checked);
                            }}
                          />
                          <span className="slider"></span>
                        </Label>
                      </div>
                    )}
                </div>
                <Controller
                  name="category"
                  render={({ onChange }) => (
                    <Select
                      value={
                        (getValues()?.isCompenstaionNote && {
                          value: 'Candidate Compensation',
                          label: 'Candidate Compensation',
                        }) ||
                        category
                      }
                      options={filterNoteCategories(type)}
                      onChange={(event: SelectorOptionType) => {
                        if (event) {
                          setCategory(event);
                          onChange(event);
                        }
                      }}
                      placeholder="Category"
                      isDisabled={
                        getValues()?.isCompenstaionNote ? true : false
                      }
                      styles={customStylesSelect}
                    />
                  )}
                  control={control}
                  rules={{
                    required: {
                      value: !getValues()?.isCompenstaionNote,
                      message: 'Category must be selected!',
                    },
                  }}
                  styles={customStyles}
                />
                <small className="text-danger">
                  {errors?.category?.message}
                </small>
              </FormGroup>
              <FormGroup>
                <Label>
                  Note <span className="text-danger">*</span>
                </Label>
                <div className="editor-height">
                  <Controller
                    control={control}
                    name="content"
                    rules={{
                      required: {
                        value: true,
                        message: 'Add some note content!',
                      },
                    }}
                    defaultValue={null}
                    render={({ onChange, ref, value }) => (
                      <div className="editor-height">
                        <ReactQuill
                          onChange={(value) => {
                            if (!editData?.id) {
                              setNotesValue(value);
                              storeNotes(value);
                            }

                            if (reactQuillReg.test(value)) {
                              onChange(null);
                            } else {
                              onChange(value);
                            }
                          }}
                          ref={ref}
                          value={editData?.id ? value : notesValue}
                        />
                        <small className="text-danger pt-2">
                          {errors?.content?.message}
                        </small>
                      </div>
                    )}
                    styles={customStyles}
                  />
                </div>
              </FormGroup>
              <div className="text-right border-0  mt-2">
                <button
                  type="button"
                  className="buttonGenericStyle filledButton"
                  disabled={loading || createLoading}
                  onClick={handleSubmit(editData ? updateNote : createNotes)}
                >
                  {(loading || createLoading) && (
                    <Spinner
                      size="sm"
                      style={{ color: 'white' }}
                      className="mr-2"
                    />
                  )}
                  {editData ? 'Update Note' : 'Add Note'}
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}

      <Button
        className="edit-candidate"
        onClick={toggle}
        type="button"
        disabled={haveActionPersmissions}
      >
        <img src={barIcon} alt="edit-notes" className="m-0" />
      </Button>
    </div>
  );
}
)
export default AddNotesModal;
