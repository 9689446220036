import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  downloadSignedUrlFile,
  handleHtmlToPdfDownload,
  handleSubmissionMail,
} from '../../../utils/commonFn';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Spinner,
} from 'reactstrap';
import { CHANGE_SUBMISSION_STATUS, GET_COMPANY_EMAILS } from '../gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import EmailLookup from '../EmailLookup';
import { EMAIL_PATTERN, reactQuillReg } from '../../../utils/constant';
import { AppContext } from '../../../context';
import { toast } from 'react-toastify';
import { useParams, withRouter } from 'react-router';

import {
  Sidedrawer,
  DrawerHeader,
} from '../../../components/layout/Sidedrawer';
import {
  AttachFileIcon,
  DownloadOutlinedIcon,
} from '../../../assets/images/svgs';
import { outlookLink } from '../../../utils/Temp-Helper';

const SubmitModal = ({
  isOpen,
  toggle,
  companyId,
  jobOrder,
  submissionId,
  mainContact,
  refetchOwner,
  candidateDetail,
  defaultTargetSalary,
  toggleParent,
}: // isRecruiter,
  any) => {
  let { id }: any = useParams();
  const [otherMails, setOtherMails] = useState({ Cc: false, Bcc: false });
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const [searchingText, setSearchingText] = useState<string>('');
  const [submitWith, setSubmitWith] = useState('');
  const [fileErr, setFileErr] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [mailContact, setMailContact] = useState<any>({
    toContacts: [],
    ccContacts: [],
    bccContacts: [],
  });
  const [subject, setSubject] = useState<any>();
  const [bodyContent, setBodyContent] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [downloadWritup, setDownloadWritup] = useState(false);
  const [downloadResume, setDownloadResume] = useState(false);
  const [companyMails, { data }] = useLazyQuery(GET_COMPANY_EMAILS);
  const [changeSubmissionStatus] = useMutation(CHANGE_SUBMISSION_STATUS);
  const { user } = useContext(AppContext);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [resumeWriteUpSend, setResumeWriteUpSend] = useState<any>({
    resumeSend: true,
    writeUpSend: true,
  });

  useEffect(() => {
    if (companyId) {
      companyMails({ variables: { companyId } });
    }

    // eslint-disable-next-line
  }, [companyId]);

  const getSelectedEmail = (value: any) => {
    const mails = mailContact?.toContacts.filter((mail) => mail !== value);
    setMailContact({
      ...mailContact,
      toContacts: [...mails, value],
    });
    setIsSelectToggle(false);
    setSearchingText('');
  };

  useEffect(() => {
    if (mailContact) {
      setMailContact({
        toContacts: [],
        ccContacts: [],
        bccContacts: [],
      });
    }

    // eslint-disable-next-line
  }, [companyId]);

  const getExtension = (filename: string) => {
    let regex = new RegExp('[^.]+$');
    return filename.match(regex);
  };

  const submissionMail = async () => {
    if (!mailContact?.toContacts?.length) {
      setErrMsg('Enter the email address!');
      return;
    } else if (!bodyContent) {
      setErrMsg('Add some content for the email!');
      return;
    }
    setErrMsg('');
    try {
      // let response = await createSubmission();
      const data: any = {};
      data.toContacts = JSON.stringify(mailContact.toContacts);
      data.ccContacts = JSON.stringify(mailContact.ccContacts);
      data.bccContacts = JSON.stringify(mailContact.bccContacts);
      data.bodyContent = bodyContent;
      data.subject = subject || '';
      // response?.data?.createSubmission?.id;
      data.submissionId = submissionId;
      // data.managerId = "user?.userManager[0]?.manager?.id";
      data.candidateId = id;
      data.companyId = companyId;
      data.jobId = jobOrder;
      data.file = selectedFiles;
      data.resumeId = resumeWriteUpSend?.resumeSend
        ? candidateDetail?.resumeId
        : '';
      data.resumeName = resumeWriteUpSend?.resumeSend
        ? `${candidateDetail?.candidateName}-resume.${getExtension(
          candidateDetail?.resumeName
        )}`
        : '';
      data.resumeUrl = resumeWriteUpSend?.resumeSend
        ? candidateDetail?.resumeUrl
        : '';
      data.writeUp = resumeWriteUpSend?.writeUpSend
        ? JSON.stringify({
          writeUpName: `${candidateDetail?.candidateName}-writeUp.pdf`,
          writeUp: candidateDetail?.writeUp,
        })
        : '';
      setLoading(true);
      let res = await handleSubmissionMail(data);

      if (res?.status === 406) {
        toast.info(res?.message);
        setLoading(false);
      }
      if (res?.status === 200) {
        setLoading(false);
        toast.success('The submission package has been created successfully');
        toggleParent();
        refetchOwner()
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.info(error);
    }
  };

  const manualSubmission = async () => {
    setLoading(true);
    let res = await changeSubmissionStatus({
      variables: {
        submissionId,
        resumeId: candidateDetail?.resumeId,
        companyId: companyId,
      },
    });

    if (res?.errors?.length) {
      toast.info(res?.errors?.[0]?.message);
      setLoading(false);
      return;
    }
    toast.success('The submission package has been created successfully');
    setLoading(false);
    toggleParent();
    refetchOwner()
  };

  const mailSelected = (e: any) => {
    if (e.keyCode === 13) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        setErrMsg('Email is not valid!');
      }
    }
  };

  const checkMail = (e) => {
    if (e.target.value) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        setErrMsg('Email is not valid!');
      }
    }
  };

  const removeAttachment = (indexOf: number) => {
    let files = [...selectedFiles];
    files = files.filter((item, index) => index !== indexOf);
    setSelectedFiles(files);
  };

  useEffect(() => {
    if (mainContact?.length) {
      let toContacts = mainContact?.find((item) => item.isPrimary);
      toContacts &&
        setMailContact({ ...mailContact, toContacts: [toContacts?.email] });
    }
    setResumeWriteUpSend({
      resumeSend: true,
      writeUpSend: true,
    });
    // eslint-disable-next-line
  }, [mainContact]);

  useEffect(() => {
    if (!user?.outLookToken) {
      setSubmitWith('Manual');
    } else {
      setSubmitWith('Ats');
    }
  }, [user?.outLookToken])

  return (
    <Sidedrawer toggle={() => { }} isOpen={isOpen}>
      <div className="mb-4">
        <DrawerHeader
          prevClick={() => {
            setSubmitWith('Ats');
            toggle(!isOpen);
            setSelectedFiles([]);
          }}
          heading="Sending Submission Package"
          // nextButtonView={false}
          nextClick={() => {
            submitWith === 'Ats' && user?.outLookToken
              ? submissionMail()
              : manualSubmission();
          }}
          buttonText={submitWith === 'Manual' ? 'Ok' : 'Send'}
          loading={loading}
          nextButtonView={false}
        />
      </div>

      <div>
        <div className="pb-3">
          <Label className="text-14 text-blackele pb-3">Submit Candidate</Label>
          <div className="relocate-allowed">
            <FormGroup check className="p-0">
              <Label className="radio-container">
                Via Email
                <Input
                  type="radio"
                  value="Ats"
                  name="submitCandidate"
                  onChange={({ target: { value } }) => {
                    setSubmitWith(value);
                    setErrMsg('');
                  }}
                  defaultChecked={!user?.outLookToken ? false : true}
                // checked={submitWith === 'Ats'}
                />
                <span className="checkmark"></span>
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label className="radio-container">
                Manually
                <Input
                  type="radio"
                  name="submitCandidate"
                  value="Manual"
                  onChange={({ target: { value } }) => {
                    setSubmitWith(value);
                    setErrMsg('');
                  }}
                  defaultChecked={!user?.outLookToken ? true : false}
                />
                <span className="checkmark"></span>
              </Label>
            </FormGroup>
          </div>
        </div>
        {submitWith === 'Ats' && (
          <>
            <div
              id="submittedEmail"
              className="writeup-wrap submission-package"
            >
              {user?.outLookToken ? (
                <Form>
                  <Card className="border-0">
                    <CardBody className="p-0">
                      <div className="dollar-addon-btn">
                        <Label className="text-14 text-blackele pb-3">
                          Email
                        </Label>
                        <div className="to-header">
                          <InputGroup className="shadow-none">
                            <InputGroupAddon
                              addonType="prepend"
                              className="styleCss"
                            >
                              To
                            </InputGroupAddon>

                            {/* here is Badges or input field */}
                            <div style={{ width: '74%' }} className="">
                              <div className="d-flex">
                                {mailContact.toContacts?.map((item) => (
                                  <div className="position-relative  d-flex">
                                    <Badge
                                      color="light"
                                      className="mx-1 my-auto text-truncate textTruncateWidth"
                                      style={{
                                        padding: '5px 20px 5px 5px ',
                                      }}
                                    >
                                      {item}
                                    </Badge>
                                    <span
                                      className="forMailRemove text-dark"
                                      onClick={() => {
                                        let toContacts = [
                                          ...mailContact.toContacts,
                                        ];
                                        let remove = toContacts.filter(
                                          (val) => val !== item
                                        );
                                        setMailContact({
                                          ...mailContact,
                                          toContacts: [...remove],
                                        });
                                      }}
                                    >
                                      x
                                    </span>
                                  </div>
                                ))}
                                <Input
                                  style={{
                                    border: '0px',
                                  }}
                                  autoComplete="off"
                                  name="mailTo"
                                  onClick={() => {
                                    setIsSelectToggle(true);
                                  }}
                                  onChange={({
                                    target,
                                  }: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchingText(target.value);
                                  }}
                                  value={searchingText}
                                />
                              </div>
                            </div>

                            {/* end here */}

                            <InputGroupAddon
                              addonType="prepend"
                              className="styleCss"
                            >
                              <div className="input-group-text">
                                <Button
                                  className="bg-transparent border-0 text-muted btn-sm p-0 mx-2 otherMails"
                                  type="button"
                                  onClick={() =>
                                    setOtherMails({
                                      ...otherMails,
                                      Cc: !otherMails.Cc,
                                    })
                                  }
                                >
                                  Cc
                                </Button>
                                <Button
                                  className="bg-transparent border-0 text-muted btn-sm p-0 otherMails"
                                  type="button"
                                  onClick={() =>
                                    setOtherMails({
                                      ...otherMails,
                                      Bcc: !otherMails.Bcc,
                                    })
                                  }
                                >
                                  Bcc
                                </Button>
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                        {isSelectToggle && searchingText.length > 2 && (
                          <EmailLookup
                            emails={data?.getCompanyEmails?.filter(
                              (item: any) => {
                                const matchingRegex = new RegExp(
                                  `^${searchingText}`,
                                  'i'
                                );
                                if (
                                  item.email.match(matchingRegex) ||
                                  item?.fullName.match(matchingRegex)
                                ) {
                                  return item;
                                } else {
                                  // eslint-disable-next-line
                                  return;
                                }
                              }
                            )}
                            setSelectedEmail={getSelectedEmail}
                          />
                        )}
                        {otherMails?.Cc && (
                          <div
                            className="border border-bottom-0"
                            style={{ borderColor: '#e5e9f8' }}
                          >
                            <InputGroup className="shadow-none border-bottom-0 rounded-0">
                              <InputGroupAddon
                                addonType="prepend"
                                className="styleCss"
                              >
                                Cc
                              </InputGroupAddon>
                              <div style={{ width: '74%' }} className="">
                                <div className="d-flex">
                                  {mailContact.ccContacts?.map((item) => (
                                    <div className="position-relative d-flex">
                                      <Badge
                                        color="light"
                                        className="mx-1 my-auto text-truncate textTruncateWidth"
                                        style={{
                                          padding: '5px 20px 5px 5px ',
                                        }}
                                      >
                                        {item}
                                      </Badge>
                                      <span
                                        className="forMailRemove text-dark"
                                        onClick={() => {
                                          let ccContacts = [
                                            ...mailContact.ccContacts,
                                          ];
                                          let remove = ccContacts.filter(
                                            (val) => val !== item
                                          );
                                          setMailContact({
                                            ...mailContact,
                                            ccContacts: [...remove],
                                          });
                                        }}
                                      >
                                        x
                                      </span>
                                    </div>
                                  ))}
                                  <Input
                                    style={{
                                      border: '0px',
                                    }}
                                    autoComplete="off"
                                    name="ccContacts"
                                    onKeyUp={(e: any) => mailSelected(e)}
                                    onBlur={(e) => checkMail(e)}
                                  />
                                </div>
                              </div>
                            </InputGroup>
                          </div>
                        )}
                        {otherMails.Bcc && (
                          <div
                            className="border border-bottom-0"
                            style={{ borderColor: '#e5e9f8' }}
                          >
                            <InputGroup className="shadow-none">
                              <InputGroupAddon
                                addonType="prepend"
                                className="styleCss"
                              >
                                Bcc
                              </InputGroupAddon>
                              <div style={{ width: '74%' }} className="">
                                <div className="d-flex">
                                  {mailContact.bccContacts?.map((item) => (
                                    <div className="position-relative  d-flex">
                                      <Badge
                                        color="light"
                                        className="mx-1 my-auto text-truncate textTruncateWidth"
                                        style={{
                                          padding: '5px 20px 5px 5px ',
                                        }}
                                      >
                                        {item}
                                      </Badge>
                                      <span
                                        className="forMailRemove text-dark"
                                        onClick={() => {
                                          let bccContacts = [
                                            ...mailContact.bccContacts,
                                          ];
                                          let remove = bccContacts.filter(
                                            (val) => val !== item
                                          );
                                          setMailContact({
                                            ...mailContact,
                                            bccContacts: [...remove],
                                          });
                                        }}
                                      >
                                        x
                                      </span>
                                    </div>
                                  ))}
                                  <Input
                                    autoComplete="off"
                                    name="bccContacts"
                                    onKeyUp={(e: any) => mailSelected(e)}
                                    onBlur={(e) => checkMail(e)}
                                    className="border-0"
                                  />
                                </div>
                              </div>
                            </InputGroup>
                          </div>
                        )}
                        <div className="to-subject">
                          <InputGroup className="shadow-none">
                            <InputGroupAddon
                              addonType="prepend"
                              className="styleCss"
                            >
                              Subject
                            </InputGroupAddon>
                            <Input
                              autoComplete="off"
                              className="border-0"
                              name="subject"
                              onChange={({ target: { value } }) => {
                                setSubject(value);
                              }}
                            />
                          </InputGroup>
                        </div>
                        <div>
                          <div className="editor-height">
                            <ReactQuill
                              key={companyId}
                              defaultValue={defaultTargetSalary}
                              onChange={(value) => {
                                if (reactQuillReg.test(value)) {
                                  setBodyContent(null);
                                } else {
                                  setBodyContent(value);
                                }
                              }}
                            />
                          </div>
                          <div className="user-info upload-btn-wrapper mt-3">
                            <Input
                              type="file"
                              multiple
                              // accept=".doc, .docx,.pdf"
                              onChange={async ({ target }) => {
                                let files: any = target.files;
                                if (files?.length) {
                                  for (const file of files) {
                                    if (file.size > 30 * 1024 * 1024) {
                                      return setFileErr(
                                        "Email could't sport the file larger then 30 Mbs."
                                      );
                                    }
                                  }
                                }
                                setFileErr('');
                                setSelectedFiles([...selectedFiles, ...files]);
                              }}
                            />

                            <Button
                              type="button"
                              className="btn-outline_prime border-none bg-transparent px-0"
                            >
                              <AttachFileIcon /> Attach File
                            </Button>
                          </div>
                          <small className="d-block text-info">{fileErr}</small>

                          <div className="px-4 pt-2">
                            {candidateDetail?.resumeUrl &&
                              candidateDetail?.resumeName && (
                                <div className="d-flex pb-2">
                                  <Input
                                    type="checkbox"
                                    checked={resumeWriteUpSend.resumeSend}
                                    onChange={({ target: { checked } }) => {
                                      setResumeWriteUpSend({
                                        ...resumeWriteUpSend,
                                        resumeSend: checked,
                                      });
                                    }}
                                  />
                                  <Button
                                    color="light"
                                    className="ml-2 my-auto curser-pointer d-flex  btn-outline_prime px-0 bg-transparent text-myWarning"
                                    style={{
                                      padding: '0',
                                      fontSize: '14px',
                                    }}
                                    disabled={downloadResume}
                                    onClick={async () => {
                                      if (candidateDetail?.resumeUrl) {
                                        setDownloadResume(true);
                                        await downloadSignedUrlFile(
                                          candidateDetail?.resumeUrl,
                                          candidateDetail?.resumeName
                                        );
                                        setDownloadResume(false);
                                      }
                                    }}
                                  >
                                    {/* <img
                                      src={DownloadImg}
                                      alt="download"
                                      className={`mr-2 mb-1 ${downloadResume && "blink_me"
                                        }`}
                                    />{" "} */}
                                    <div className="text-truncate textTruncateWidth text-left text-myWarning">
                                      {`${candidateDetail?.candidateName
                                        }-resume.${getExtension(
                                          candidateDetail?.resumeName
                                        )}`}
                                    </div>
                                  </Button>
                                </div>
                              )}
                            {candidateDetail?.candidateName &&
                              candidateDetail?.writeUp && (
                                <div className="d-flex align-items-center">
                                  <Input
                                    type="checkbox"
                                    name="writeUp"
                                    checked={resumeWriteUpSend.writeUpSend}
                                    onChange={({ target: { checked } }) => {
                                      setResumeWriteUpSend({
                                        ...resumeWriteUpSend,
                                        writeUpSend: checked,
                                      });
                                    }}
                                  />
                                  <Button
                                    color="light"
                                    className="ml-2 my-auto curser-pointer d-flex btn-outline_prime  bg-transparent"
                                    style={{
                                      padding: '0',
                                      fontSize: '14px',
                                    }}
                                    onClick={async () => {
                                      if (candidateDetail?.writeUp) {
                                        setDownloadWritup(true);
                                        await handleHtmlToPdfDownload(
                                          candidateDetail?.writeUp,
                                          `${candidateDetail?.candidateName}-writeUp.pdf`
                                        );
                                        setDownloadWritup(false);
                                      }
                                    }}
                                    disabled={downloadWritup}
                                  >
                                    {/* <img
                                      src={DownloadImg}
                                      alt="download"
                                      className={`mr-2 mb-1 ${downloadWritup && "blink_me"
                                        }`}
                                    /> */}
                                    {`${candidateDetail?.candidateName}-writeUp.pdf`}
                                  </Button>
                                </div>
                              )}
                          </div>

                          {selectedFiles?.map((item, index) => (
                            <div>
                              <div className="d-flex pt-2 mb-2 mx-4 upload-document-list">
                                <div
                                  className="position-relative  d-flex"
                                  key={index}
                                >
                                  <Badge
                                    color="light"
                                    className="mx-1 my-auto text-truncate text-14 font-weight-normal textTruncateWidth text-myWarning badge-submission-light"
                                    style={{
                                      padding: '5px 20px 5px 5px ',
                                    }}
                                  >
                                    {item?.name}
                                  </Badge>
                                  <span
                                    className="forMailRemove text-myWarning"
                                    style={{ top: '-2px' }}
                                    onClick={() => removeAttachment(index)}
                                  >
                                    x
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <small className="text-danger">{errMsg}</small>
                    </CardBody>
                  </Card>
                </Form>
              ) : (
                <Card className="border-0">
                  <CardBody className="p-0">
                    <div className="d-flex px-3 py-5 border">
                      <span>
                        Signin with your <strong>Outlook </strong> account
                        first!
                      </span>
                      {outlookLink}
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </>
        )}

        {submitWith === 'Manual' && (
          <div>
            <Label className="text-14 text-blackele pb-2">
              Download Details
            </Label>

            <div className={`pb-3 ${downloadWritup ? 'blink_me' : ''}`}>
              <Button
                type="button"
                size="sm"
                disabled={downloadWritup}
                className="agreement-download btn-outline_prime bg-transparent ml-0 p-0  d-flex align-items-center"
                style={{ fontSize: '16px' }}
                onClick={async () => {
                  setDownloadWritup(true);
                  await handleHtmlToPdfDownload(
                    candidateDetail?.writeUp,
                    `${candidateDetail?.candidateName || ''}-writeUp`
                  );
                  setDownloadWritup(false);
                }}
              >
                <DownloadOutlinedIcon />

                <small className="text-14 text-myWarning pl-2">
                  {candidateDetail?.candidateName} Writeup
                </small>
                {/* {!downloadWritup ? "Download" : "Downloading"} */}
              </Button>
            </div>

            <div className={`pb-3 ${downloadResume ? 'blink_me' : ''}`}>
              <Button
                size="sm"
                type="button"
                disabled={downloadResume}
                style={{ fontSize: '16px' }}
                className="agreement-download btn-outline_prime bg-transparent ml-0 p-0 d-flex align-items-center"
                onClick={async () => {
                  if (!candidateDetail?.resumeUrl) {
                    setErrMsg('Please Select a Resume');
                  } else {
                    setErrMsg('');
                    setDownloadResume(true);
                    // await downloadDocxFile(
                    //   candidateDetail?.resumeUrl,
                    //   `${candidateDetail?.candidateName}-resume.pdf`
                    // );
                    await downloadSignedUrlFile(
                      candidateDetail?.resumeUrl,
                      candidateDetail?.resumeName
                    );
                    setDownloadResume(false);
                  }
                }}
              >
                <DownloadOutlinedIcon />
                <small className="text-14 text-myWarning pl-2">
                  {`${candidateDetail?.candidateName}-resume.${getExtension(
                    candidateDetail?.resumeName
                  )}`}
                </small>
              </Button>
            </div>
            <small className="text-danger">{errMsg && errMsg}</small>
          </div>
        )}

        <div className="text-right py-3">
          <button
            className="buttonGenericStyle filledButton"
            type="button"
            disabled={loading || (!user?.outLookToken && submitWith === 'Ats')}
            onClick={() => {
              submitWith === 'Ats' && user?.outLookToken
                ? submissionMail()
                : manualSubmission();
            }}
          >
            {loading && <Spinner size="sm" className="mr-2" />}
            {submitWith === 'Manual' ? 'Ok' : 'Submit'}
          </button>
        </div>
      </div>
    </Sidedrawer>
  );
};

export default withRouter(SubmitModal);
