import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import FeedbackModal from '../../../../components/FeedbackModal';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../components/layout/Sidedrawer';
import { AppContext } from '../../../../context';
import {
  createMarkup,
  downloadSignedUrlFile,
  truncateDocumentName,
} from '../../../../utils/commonFn';
import { submissionActionTypes } from '../../../../utils/constant';
import { countryWiseDetailedlocation } from '../../../../utils/helper';
import DeclineSubmissionModal from '../../../candidate-submission/DeclineSubmissionModal';
import { FETCH_SUBMISSION_EMAIL } from '../../../candidate-submission/gql';
import SubmissionReview from '../../../candidate-submission/Pipeline-components/SubmissionReview';
import SubmitModal from '../../../candidate-submission/Pipeline-components/SubmitModal';
import SubmissionHistory from '../../../candidate-submission/SubmissionHistory';
import JobOrderDetailWidgetForDrawer from '../../candidate-components/JobOrderDetailWidgetForDrawer';
import RejectSubmission from './RejectSubmission';
import SubmissionDetailDrawer from './SubmissionDetailDrawer';
import { GET_SIGNED_URL, GET_SUBMISSION_ATTACHMENTS } from '../../gql';
import DownloadAndLinkView from '../../candidate-components/DownloadAndLinkView';

const SubmissionViewDrawer = ({
  toggle,
  isOpen,
  applicantData,
  refetch,
  refetchOwner,
  setCandidateFilter,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [feedbackDetails] = useState<any>(null);
  const [manualAlert, setManualAlert] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [emailPreview, setEmailPreview] = useState(false);
  const [submitModal, setSubmitModal] = useState<any>(false);
  const [submissionDetailToggle, setSubmissionDetailToggle] =
    useState<any>(false);
  const [companyOwnerShares, setCompanyOwnerShares] = useState<any>();
  const [submissionRejectToggle, setSubmissionRejectToggle] =
    useState<boolean>(false);
  const [submissionDeclineToggle, setSubmissionDeclineToggle] =
    useState<boolean>(false);
  const { user } = useContext(AppContext);
  const [fetchEmailDetails, { data }] = useLazyQuery(FETCH_SUBMISSION_EMAIL);
  const [getSubmissionAttachments, { data: submissionAttachments }] =
    useLazyQuery(GET_SUBMISSION_ATTACHMENTS);
  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);


  const onResumeDownload = async (url, fileName) => {
    setDownloading(true);
    await downloadSignedUrlFile(url, fileName);
    setDownloading(false)
  };

  const generateSignedUrlAttachment = async (
    urlToGetSignedUrl: string
  ) => {
    const res = await signedUrlGetter({
      variables: {
        remoteFilePath: urlToGetSignedUrl,
      },
    });

    return await res?.data?.getSignedUrl?.signedUrl
  }

  const handleAction = (value: string) => {
    switch (value) {
      case 'SUBMIT':
        setSubmitModal(!submitModal);
        break;

      case 'DECLINE_SUBMISSION':
        setSubmissionDeclineToggle(!submissionDeclineToggle);
        break;

      case 'REJECT_SUBMISSION':
        setSubmissionRejectToggle(!submissionRejectToggle);
        break;

      default:
        break;
    }
  };

  const submissionEmailPreview = () => {
    if (
      data?.getSubmission?.status === 'COMPLETED' &&
      data?.getSubmission?.submitWith === 'MANUAL'
    ) {
      setManualAlert(!manualAlert);
    } else {
      setEmailPreview(!emailPreview);
    }
  };

  useEffect(() => {
    if (applicantData && user) {
      let candidateOwner = applicantData?.candidate?.recruiterOwner?.id;
      let companyOwner = applicantData?.JobOrder?.company?.ownerAndSharees?.map(
        (item) => item?.owner?.id
      );

      setCompanyOwnerShares(companyOwner);
      let submissionStatus = applicantData?.submission?.status !== 'REJECTED';

      setEditPermission(
        submissionStatus &&
        (user?.id === candidateOwner || companyOwner.includes(user?.id))
      );
    }
    if (applicantData?.submission?.id) {
      fetchEmailDetails({
        variables: { submissionId: applicantData?.submission?.id },
      });
      getSubmissionAttachments({
        variables: { submissionId: applicantData?.submission?.id }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantData, user]);

  const actionValuesPermission = (item) => {
    if (
      applicantData?.JobAppStatus === 'CLIENT_DECLINE' ||
      applicantData?.JobAppStatus === 'CANDIDATE_DECLINE'
    ) {
      return true;
    }
    if (
      item?.value === 'DECLINE_SUBMISSION' &&
      applicantData?.submission?.status === 'DECLINE'
    ) {
      return true;
    }
    if (
      (item?.value === 'REJECT_SUBMISSION' || item?.value === 'SUBMIT') &&
      !companyOwnerShares?.includes(user?.id)
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen}>
        <div className="mb-4">
          <DrawerHeader
            prevClick={() => toggle()}
            nextClick={() => {
              if (applicantData?.submission?.status === 'COMPLETED') {
                submissionEmailPreview();
              } else {
                setSubmissionDetailToggle(true);
              }
            }}
            buttonText={
              applicantData?.submission?.status === 'COMPLETED'
                ? 'Preview'
                : 'Edit'
            }
            heading="Submission Detail"
            // prevButtonView={false}
            disabled={
              applicantData?.JobAppStatus === 'CLIENT_DECLINE' ||
              applicantData?.JobAppStatus === 'CANDIDATE_DECLINE'
            }
            nextButtonView={
              editPermission ||
              applicantData?.submission?.status === 'COMPLETED'
            }
          >
            {editPermission &&
              applicantData?.submission?.status !== 'COMPLETED'
              && applicantData?.JobAppStatus !== 'APPLIED'
              && applicantData?.JobAppStatus !== 'REVIEWED' && (
                <div className="actionsDropdown actionsDropdownSpacing dropdown">
                  <UncontrolledDropdown className="customMenuDropdown">
                    <DropdownToggle caret style={{ minHeight: '40px' }}>
                      Actions
                    </DropdownToggle>

                    <DropdownMenu right>
                      {submissionActionTypes?.map((item, index) => (
                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          key={index}
                          onClick={() => handleAction(item?.value)}
                          disabled={actionValuesPermission(item)}
                        >
                          {item?.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
          </DrawerHeader>
        </div>

        <JobOrderDetailWidgetForDrawer applicantData={applicantData} />

        {/* step start from here */}
        <div className="pb-4">
          <div className="pb-3">
            <strong>Steps</strong>
          </div>

          <Row className="mb-2">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Salary Type</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {applicantData?.submission?.salaryType || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Current Salary</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {applicantData?.submission?.salary || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Target Salary</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {applicantData?.submission?.targetSalary || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Job Title</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {applicantData.candidate?.jobTitle || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Candidate Owner</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {applicantData?.candidate?.recruiterOwner?.fullName || '--'}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Location</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {countryWiseDetailedlocation(applicantData?.candidate?.countryCode, applicantData?.candidate?.city, applicantData?.candidate?.stateCode, applicantData?.candidate?.streetAddress, applicantData?.candidate?.postalCode, applicantData?.candidate?.candidateAddressOne, applicantData?.candidate?.candidateAddressTwo)}
              </h5>
            </Col>
          </Row>
        </div>

        <div className="pb-4">
          <div className="pb-3">
            <strong>Candidate Writeup</strong>
          </div>

          <div
            className="custom-scroller"
            style={{ maxHeight: '200px', overflowY: 'auto' }}
            dangerouslySetInnerHTML={createMarkup(
              applicantData?.submission?.submissionSummary || ''
            )}
          />

          <div />
        </div>

        <div className="pb-4">
          <div className="pb-3">
            <strong>Resume</strong>
          </div>

          <div>
            <button
              disabled={downloading || !applicantData?.submission?.resumeName}
              onClick={() =>
                onResumeDownload(
                  applicantData?.submission?.resumeUrl,
                  applicantData?.submission?.resumeName
                )
              }
              title={applicantData?.submission?.resumeName || ''}
              className="color-default bg-transparent border-0 p-0"
            >
              {downloading && <Spinner size="sm" />}{' '}
              {applicantData?.submission?.resumeName ? (
                truncateDocumentName(
                  applicantData?.submission?.resumeName || ''
                )
              ) : (
                <small className="text-info">Resume Not Found!</small>
              )}
            </button>
          </div>
        </div>

        <div className="mb-3">
          <div className="pb-3">
            <strong>Attachments</strong>
          </div>

          {submissionAttachments?.getSubmissionAttachments?.map((item, index) => {
            return <DownloadAndLinkView
              attachmentName={item?.attachmentName}
              generateSignedUrl={generateSignedUrlAttachment}
              attachmentURL={item?.attachmentURL} key={index} />
          })
          }

        </div>

        <SubmissionHistory submissionId={applicantData?.submission?.id} />
      </Sidedrawer>

      {/* used for the submission reject  */}
      {submissionRejectToggle && (
        <RejectSubmission
          submissionId={applicantData?.submission?.id}
          applicantId={applicantData?.id}
          refetch={refetch}
          isOpen={submissionRejectToggle}
          toggle={() => {
            setSubmissionRejectToggle(!submissionRejectToggle);
            toggle();
          }}
        />
      )}

      {/* this drawer is used for the submission feedback but it is reuseable for any kind of feedback */}
      {feedbackModal && (
        <FeedbackModal
          modal={feedbackModal}
          toggle={() => {
            setFeedbackModal(!feedbackModal);
          }}
          details={feedbackDetails}
        />
      )}

      {emailPreview && (
        <SubmissionReview
          data={data?.getSubmission}
          open={emailPreview}
          toggle={() => {
            setEmailPreview(!emailPreview);
          }}
        />
      )}

      {submissionDeclineToggle && (
        <DeclineSubmissionModal
          submissionId={applicantData?.submission?.id}
          applicantId={applicantData?.id}
          refetch={refetch}
          isOpen={submissionDeclineToggle}
          toggle={() => {
            setSubmissionDeclineToggle(!submissionDeclineToggle);
            toggle();
          }}
        />
      )}

      <ConfirmPopUp
        confirmText="Candidate is submitted manually!"
        isOpen={manualAlert}
        toggle={setManualAlert}
        confirmAction={() => {
          toggle();
        }}
        modalHeading="Message Alert"
        btnText="OK"
        btnColor="primary"
      />

      {submissionDetailToggle && (
        <SubmissionDetailDrawer
          toggle={() => {
            setSubmissionDetailToggle(!submissionDetailToggle);
          }}
          refetchOwner={refetchOwner}
          isOpen={submissionDetailToggle}
          jobOrderId={applicantData?.JobOrder?.id}
          candidate={applicantData?.candidate}
          applicantId={applicantData?.id}
          companyId={applicantData?.JobOrder?.company?.id}
          backClick={() => {
            setSubmissionDetailToggle(false);
          }}
          submissionId={applicantData?.submission?.id}
          refetchApplicant={refetch}
          landedFrom="mainPage"
          setCandidateFilter={setCandidateFilter}
        />
      )}
      {submitModal && (
        <SubmitModal
          isOpen={submitModal}
          toggle={setSubmitModal}
          companyId={applicantData?.JobOrder?.company?.id}
          jobOrder={applicantData?.JobOrder?.id}
          submissionId={applicantData?.submission?.id}
          defaultTargetSalary={applicantData?.submission?.targetSalary}
          mainContact={
            applicantData?.JobOrder?.mainContact?.contact?.contactEmail
          }
          refetchOwner={refetchOwner}
          toggleParent={() => {
            toggle();
            refetch();
            setCandidateFilter([{ label: 'Submitted', value: 'Submitted' }]);
          }}
          candidateDetail={{
            writeUp: applicantData?.submission?.submissionSummary,
            candidateName: applicantData?.candidate?.fullName,
            resumeId: applicantData?.submission?.resumeId,
            resumeUrl: applicantData?.submission?.resumeUrl,
            resumeName: applicantData?.submission?.resumeName,
          }}
        />
      )}
    </>
  );
};

export default SubmissionViewDrawer;
