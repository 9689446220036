// packages block
import { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from 'reactstrap';
// components block
import GoBack from '../../components/GoBack';
import Loader from '../../components/layout/Loader';
import AlertCard from '../../components/layout/Alert';
import AddNotesModal from '../../components/AddNotesModal';
import CustomButton from '../../components/layout/CustomButton';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import Attachments from '../company/company-components/Attachments';
import ActionLogsAndNotes from '../../components/ActionLogsAndNotes';
import CandidateWidget from './candidate-components/CandidateWidget';
import CandidateResume from './candidate-components/CandidateResume';
import EmploymentHistory from './candidate-components/EmploymentHistory';
import CandidateEducation from './candidate-components/CandidateEducation';
import CandidateNotesModal from './candidate-components/CandidateNotesModal';
import ReferenceListing from '../candidate-pipeline/reference/ReferenceListing';
import CandidateRecruiterModal from './candidate-components/CandidateRecruiterModal';
import SubmissionHistoryCandidate from './candidate-components/SubmissionHistoryCandidate';
import CandidateOwnershipWriteup from '../candidate/candidate-components/CandidateOwnershipWriteup';
// graphql and utils block
import { AppContext } from '../../context';
import { CHECK_NON_SOLICIT_COMPANY, GET_NOTES } from '../company/gql';
import { createMarkup } from '../../utils/commonFn';
import { IComingState, ParentRef } from '../agreements/interfaces';
import { GET_CANDIDATE_DETAILS } from './gql';
import {
  countWordsInString,
  countryWiseDetailedlocation,
  moneyFormatter,
  pushInHistory,
  trimStringUptoWords,
  countryChecker,
  countryWiseHeaderLocation,
} from '../../utils/helper';
import INFO_ICON_1 from '../../assets/images/info-icon-1.svg';
import LINKEDIN_IMG from '../../assets/images/social1.svg';
import INFO_ICON_4 from '../../assets/images/phone-icon.svg';
import INFO_ICON_5 from '../../assets/images/email-icon.svg';
import INFO_ICON_6 from '../../assets/images/location-icon.svg';
import PLUS_ICON from '../../assets/images/plus-icon.svg';
import READ_MORE_ARROW from '../../assets/images/read-arrow.svg';
import MODAL_CLOSE_ICON from '../../assets/images/modal-close.svg';
import MODAL_CLOSE_LIGHTICON from '../../assets/images/modal-close-white.svg';
import TITLE_ICON from '../../assets/images/card-title.svg';
import './candidate.scss';
import JobApplicationStatus from './candidate-components/JobApplicationStatus/JobApplicationStatus';
import CandidateSubmitDrawer from './application-flow/submission/CandidateSubmitDrawer';
import {
  ADMIN_ROLE,
  CANDIDATES_ROUTE,
  EXECUTIVE_ROLE,
  HR_ROLE,
  UPDATE_CANDIDATE_ROUTE,
  VIEW_CANDIDATE_ROUTE,
} from '../../utils/constant';
import ConfirmPopUp from '../../components/ConfirmPopUp';

const ViewCandidate = ({ match }) => {
  const updateNotesRef = useRef<ParentRef>();
  const location = useLocation<IComingState>();
  const { theme } = useContext(AppContext);
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';

  const history = useHistory();
  const comingState: any = location.state;
  const companyId = new URLSearchParams(location?.search)?.get('companyId');
  const jobOrderId = new URLSearchParams(location?.search)?.get('jobOrderId');
  const applicantId = new URLSearchParams(location?.search)?.get('applicantId');
  const submissionId = new URLSearchParams(location?.search)?.get('submissionId');

  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [summaryContent, setSummaryContent] = useState<string>('');
  const [summaryModal, setSummaryModal] = useState<boolean>(false);
  const [submitPopUp, setSubmitPopUp] = useState<boolean>(false);

  const [getCandidate, { data, loading, refetch }] = useLazyQuery(
    GET_CANDIDATE_DETAILS
  );
  const [checkNonSolicitCompanyQuery, { data: nonSolicitWarning }] = useLazyQuery(CHECK_NON_SOLICIT_COMPANY);

  const [refetchCandidateOwner, setRefetchCandidateOwner] = useState(false);
  const refetchCandidateWriteUp = () => {
    setRefetchCandidateOwner(!refetchCandidateOwner);
  };
  const [candidateId, setCandidateId] = useState('');
  const [recruiterFolder, setRecruiterFolder] = useState<boolean>(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const { user, userRoles } = useContext(AppContext);
  const [noteData, setNoteData] = useState(null);
  const [compensationNote, setCompensationNote] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const [modal, setModal] = useState(false);
  const [getNotes] = useLazyQuery(GET_NOTES);


  const _getNotes = (type: string) => {
    getNotes({
      variables: {
        id: candidateId,
        notePayload: { type: 'Candidate', compensationNote: compensationNote },
        page: 1,
        limit: 3,
      },
    });

    if (
      type === 'Phone Call' ||
      type === 'Internal Interview' ||
      type === 'Internal Web Interview' ||
      type === 'Email Sent'
    ) {
      refetchCandidateWriteUp();
    }
  };
  const [jobApply, setJobApply] = useState<boolean>(false);
  const [createApplicant, setCreateApplicant] = useState<boolean>(!!(companyId && jobOrderId && applicantId && submissionId));

  const onApplyJob = () => {
    nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) : setJobApply(!jobApply);
  };

  const createApplicantToggle = () => {
    nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) : setCreateApplicant(!createApplicant);
  };

  const editPermission = (candidate) => {
    if (
      userRoles?.includes(ADMIN_ROLE) ||
      userRoles?.includes(EXECUTIVE_ROLE) ||
      userRoles?.includes(HR_ROLE) ||
      candidate?.shareWith?.id === user?.id
    ) {
      setEditPermissions(false);
      return;
    } else if (user?.id === candidate?.recruiterOwner?.id) {
      setEditPermissions(false);
      return;
    } else if (candidate?.recruiterOwner === null) {
      setEditPermissions(false);
      return;
    } else {
      setEditPermissions(true);
      return;
    }
  };

  const summaryModalToggle = () => {
    setSummaryContent(data?.getCandidate?.summary);
    setSummaryModal(!summaryModal);
  };

  useEffect(() => {
    const FULL_NAME = `${data?.getCandidate?.firstName + ' ' + data?.getCandidate?.lastName
      }`;
    document.title = loading
      ? 'Loading...'
      : ` ${FULL_NAME || 'Candidate'} - Candidate - RealREPP`;

    // eslint-disable-next-line
  }, [data?.getCandidate, loading]);

  useEffect(() => {
    if (data?.getCandidate) {
      editPermission(data?.getCandidate);
    }
    checkNonSolicitCompanyQuery({
      variables: {
        input: data?.getCandidate?.companyName ||
          data?.getCandidate?.recentEmployment?.companyName
      }
    })
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const id = match.params.id;
    if (id) {
      setCandidateId(id);
      getCandidate({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  const getCandidateOwner = () => {
    const id = match.params.id;
    if (id) getCandidate({ variables: { id } })
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const recruiterFolderToggle = () => setRecruiterFolder(!recruiterFolder);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const toggle5 = () => setTooltipOpen5(!tooltipOpen5);
  const [tooltipOpen6, setTooltipOpen6] = useState(false);
  const toggle6 = () => setTooltipOpen6(!tooltipOpen6);

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const toggleModal = () => setModal(!modal);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={
          comingState?.from === 'private-candidates'
            ? '/private-candidates'
            : comingState?.backUrl
              ? comingState?.backUrl
              : CANDIDATES_ROUTE
        }
        search={comingState?.searchParameters}
      />
      {!nonSolicitWarning?.checkNonSolicitCompany && data?.getCandidate?.agreementWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate might be working for a client with signed agreement"
          color="danger"
        />
      )}
      {data?.getCandidate?.interviewWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate is currently going through interviews within past 30 days"
          color="danger"
        />
      )}

      {nonSolicitWarning?.checkNonSolicitCompany && (
        <AlertCard
          title="Warning!"
          subTitle={"Candidate might be working with a client with non-solicit agreement!"}
          color="danger"
        />
      )}

      {data?.getCandidate?.placementWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate was placed within past year"
          color="danger"
        />
      )}

      {submitPopUp && (
        <ConfirmPopUp
          confirmText="You cannot submit a candidate from a client with non-solicit agreement."
          isOpen={submitPopUp}
          toggle={setSubmitPopUp}
          confirmAction={() => setSubmitPopUp(!submitPopUp)}
          modalHeading="Submit Action"
          btnText="Okay"
          cancelBtn={false}
          className="revampDialog revampDialogWidth"
        />)
      }

      {countryChecker(data?.getCandidate?.countryCode) ? null : (
        <AlertCard
          title="Warning!"
          subTitle="Candidate is from outside of the US"
          color="danger"
          outSideUS={true}
        />
      )
      }
      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <div className="d-flex align-items-center">
          <h5 className="m-0 mr-2">{data?.getCandidate?.fullName || '--'}</h5>

          <Badge
            color={
              data?.getCandidate?.status === 'ACTIVE'
                ? 'success'
                : data?.getCandidate?.status === 'DO_NOT_CONTACT'
                  ? 'danger'
                  : ''
            }
            className="text-capitalize"
          >
            {data?.getCandidate?.status ?? '--'}
          </Badge>
        </div>

        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="actionsDropdown actionsDropdownSpacing"
        >
          <DropdownToggle caret>Actions</DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              disabled={editPermissions}
              tag={Link}
              onClick={() =>
                pushInHistory(
                  `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
                  location.search
                )
              }
              to={`${UPDATE_CANDIDATE_ROUTE}/${candidateId}`}
              className="cursor-pointer m-0"
            >
              Edit
            </DropdownItem>
            <DropdownItem
              tag={Link}
              onClick={() =>
                pushInHistory(
                  `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
                  location.search
                )
              }
              to={
                data?.getCandidate?.resume?.length
                  ? {
                    pathname: `/view/resume/${candidateId}`,
                    search: `?resumeId=${data?.getCandidate?.resume[0]?.id}`,
                  }
                  : {
                    pathname: `/view/resume/${candidateId}`,
                  }
              }
              className="cursor-pointer m-0"
            >
              Resume Preview
            </DropdownItem>
            <DropdownItem onClick={onApplyJob}>Submit</DropdownItem>

            <DropdownItem onClick={createApplicantToggle}>Apply</DropdownItem>

            <DropdownItem onClick={toggleModal}>Activity Notes</DropdownItem>

            <DropdownItem onClick={recruiterFolderToggle}>
              Add To Recruiter Folder
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="applicantDetail primaryHeaderSpacing view-candidate-container">
        {data?.getCandidate?.linkedInUrl && (
          <>
            <div className="infoCardDivider">
              <a
                href={
                  data?.getCandidate?.linkedInUrl
                    ? data?.getCandidate?.linkedInUrl
                    : '#'
                }
                target={data?.getCandidate?.linkedInUrl ? '_blank' : ''}
                className="m-0 d-flex"
                rel="noreferrer"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen1}
                  target="TooltipExample1"
                  toggle={toggle1}
                >
                  Linkedin
                </Tooltip>

                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="17px"
                  id="TooltipExample1"
                />
              </a>
            </div>
            <div className="divider"></div>
          </>
        )}

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen2}
            target="TooltipExample2"
            toggle={toggle2}
          >
            Job Title
          </Tooltip>

          <img
            src={TITLE_ICON}
            alt="address"
            width="19px"
            id="TooltipExample2"
          />
          <label>
            {' '}
            {data?.getCandidate?.jobTitle ||
              data?.getCandidate?.recentEmployment?.jobTitle ||
              '--'}
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen3}
            target="TooltipExample3"
            toggle={toggle3}
          >
            Company
          </Tooltip>
          <img
            src={INFO_ICON_1}
            alt="company"
            width="17px"
            id="TooltipExample3"
          />
          <label>
            {data?.getCandidate?.companyName ||
              data?.getCandidate?.recentEmployment?.companyName ||
              '--'}
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen4}
            target="TooltipExample4"
            toggle={toggle4}
          >
            Address
          </Tooltip>
          <img
            src={INFO_ICON_6}
            alt="address"
            width="17px"
            id="TooltipExample4"
          />
          <label>
            {
              countryWiseHeaderLocation(data?.getCandidate?.countryCode, data?.getCandidate?.city, data?.getCandidate?.stateCode)
            }
          </label>
        </div>

        <div className="divider"></div>
        {data?.getCandidate?.phones?.length ? (
          <>
            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen5}
                target="TooltipExample5"
                toggle={toggle5}
              >
                Phone
              </Tooltip>
              <img
                src={INFO_ICON_4}
                alt="phone"
                width="17px"
                id="TooltipExample5"
              />
              <label>
                <div className="d-flex align-items-center">
                  <a
                    href={`tel:${data?.getCandidate?.phones?.length
                      ? data?.getCandidate?.phones?.find(
                        (val) => val?.isPrimary
                      )?.phoneNumber ||
                      data?.getCandidate?.phones[0]?.phoneNumber
                      : '--'
                      }`}
                    className="m-0"
                  >
                    {data?.getCandidate?.phones?.length
                      ? data?.getCandidate?.phones?.find(
                        (val) => val?.isPrimary
                      )?.phoneNumber ||
                      data?.getCandidate?.phones[0]?.phoneNumber
                      : '--'}
                  </a>
                </div>
              </label>
            </div>
            <div className="divider"></div>{' '}
          </>
        ) : null}

        <div className="infoCardDivider d-flex align-items-center emailLengthWidth">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen6}
            target="TooltipExample6"
            toggle={toggle6}
          >
            Email
          </Tooltip>
          <img
            src={INFO_ICON_5}
            alt="emails"
            width="19px"
            id="TooltipExample6"
          />
          <label>
            <a
              href={`mailto:${data?.getCandidate?.emails?.length
                ? data?.getCandidate?.emails?.find(
                  (item: { isPrimary: boolean }) => item.isPrimary
                )?.email || data?.getCandidate?.emails[0]?.email
                : '--'
                }`}
              className="m-0"
            >
              {(data?.getCandidate?.emails?.length &&
                data?.getCandidate?.emails?.find(
                  (item: { isPrimary: boolean }) => item.isPrimary
                )?.email) ||
                (data?.getCandidate?.emails?.length &&
                  data?.getCandidate?.emails[0]?.email) ||
                '--'}
            </a>
          </label>
        </div>
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs className="navTabsOverflow">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Resume
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Activity
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Candidate Ownership
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Submission
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              References
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              Documents
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              Job App Status
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '10' })}
              onClick={() => {
                toggleTab('10');
              }}
            >
              Employment
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '11' })}
              onClick={() => {
                toggleTab('11');
              }}
            >
              Education
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <Row>
              <Col lg="6" md="12" sm="12">
                <div className="">
                  <h5>Personal details</h5>

                  <div>
                    <div className="d-flex justify-content-between align-items-start mb-2 contentGridLabel">
                      <label>Phone</label>

                      <div
                        className="contentGrid"
                        style={{ lineHeight: '16px' }}
                      >
                        <div className=" align-items-center">
                          {data?.getCandidate?.phones?.length
                            ? data?.getCandidate?.phones?.map(
                              (item: { phoneNumber: string }) => (
                                <div>
                                  <a
                                    href={`tel:${data?.getCandidate?.phones}`}
                                  >
                                    {item?.phoneNumber}
                                  </a>
                                </div>
                              )
                            )
                            : '--'}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-start mb-2 contentGridLabel">
                      <label>Email</label>

                      <div
                        className="contentGrid emailLengthWidth"
                        style={{ lineHeight: '16px' }}
                      >
                        {data?.getCandidate?.emails?.length
                          ? data?.getCandidate?.emails?.map(
                            (item: { email: string }) => (
                              <div>
                                <a href={`mailto:${item?.email}`}>
                                  {item?.email}
                                </a>
                              </div>
                            )
                          )
                          : '--'}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Address</label>

                      <div className="contentGrid">
                        <h6>
                          {
                            countryWiseDetailedlocation(data?.getCandidate?.countryCode, data?.getCandidate?.city, data?.getCandidate?.stateCode, data?.getCandidate?.streetAddress, data?.getCandidate?.postalCode, data?.getCandidate?.address1, data?.getCandidate?.address2)
                          }
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Current Company</label>

                      <div className="contentGrid">
                        <h6>
                          {data?.getCandidate?.companyName ||
                            data?.getCandidate?.recentEmployment?.companyName ||
                            data?.recentEmployment?.companyName ||
                            '--'}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Seniority</label>

                      <div className="contentGrid">
                        <h6>
                          {data?.getCandidate?.seniority || '--'}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Current Job Title</label>

                      <div className="contentGrid">
                        <h6>
                          {data?.getCandidate?.jobTitle ||
                            data?.getCandidate?.recentEmployment?.jobTitle ||
                            data?.recentEmployment?.jobTitle ||
                            '--'}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Status</label>

                      <div className="contentGrid">
                        <h6>{data?.getCandidate?.status || '--'}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="idealEmployment">
                  <h5>Ideal Employment</h5>

                  <div>
                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label> Ideal Job Title</label>

                      <div className="contentGrid">
                        <h6>{data?.getCandidate?.idealJobTitle || '--'}</h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Desired Salary</label>

                      <div className="contentGrid">
                        <h6>{data?.getCandidate?.desiredSalary || '--'}</h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Min-Max Range</label>

                      <div className="contentGrid">
                        <h6>
                          {moneyFormatter(data?.getCandidate?.salaryMin || 0)} -
                          {'  '}
                          {moneyFormatter(data?.getCandidate?.salaryMax || 0)}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2 contentGridLabel">
                      <label>Work Type</label>

                      <div className="contentGrid">
                        <h6>{data?.getCandidate?.idealWorkType || '--'}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="6" md="12" sm="12">
                <div>
                  <CandidateWidget stats={data?.getCandidate?.candidateStats} />
                </div>

                <div className="summarySection">
                  <h5>Summary</h5>

                  {data?.getCandidate?.summary &&
                    countWordsInString(data?.getCandidate?.summary, 20) ? (
                    <>
                      <p>
                        {trimStringUptoWords(data?.getCandidate?.summary, 10)}
                      </p>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={summaryModalToggle}
                      >
                        Read More <img src={READ_MORE_ARROW} alt="arrow" />
                      </button>
                    </>
                  ) : (
                    <p>{data?.getCandidate?.summary || '--'}</p>
                  )}
                </div>

                <div className="skillsSection">
                  <h5>Skills</h5>
                  <div className="d-flex flex-wrap">
                    {data?.getCandidate.skillsSet?.length
                      ? data?.getCandidate?.skillsSet?.map((v) => (
                        <span className="skillBadge">{v?.skill?.name}</span>
                      ))
                      : '--'}
                  </div>
                </div>

                <div className="otherDetailsSection">
                  <h5>Other details</h5>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Source</label>

                    <div className="contentGrid">
                      <h6>{data?.getCandidate?.source || '--'}</h6>
                    </div>
                  </div>

                  <SocialMediaIcons
                    facebook={data?.getCandidate?.fbUrl}
                    twitter={data?.getCandidate?.twitterUrl}
                    linkedin={data?.getCandidate?.linkedInUrl}
                    instagram={data?.getCandidate?.instaUrl}
                  />

                  <div className="d-flex justify-content-between folderSection contentGridLabel">
                    <label>Folder</label>

                    <div className="contentGrid">
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={recruiterFolderToggle}
                      >
                        <img src={PLUS_ICON} alt="plus" />
                        Add to Folder
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <CandidateResume
              refetch={refetch}
              necessaryCandidateInfo={{
                jobTitle: data?.getCandidate?.jobTitle ||
                  data?.getCandidate?.recentEmployment?.jobTitle,
                companyName: data?.getCandidate?.companyName ||
                  data?.getCandidate?.recentEmployment?.companyName,
                phones: data?.getCandidate?.phones?.length ?
                  data?.getCandidate?.phones : null,
                emails: data?.getCandidate?.emails?.length ?
                  data?.getCandidate?.emails : null
              }}
              candidateId={candidateId}
              uploadResume={!editPermissions}
              candidateName={data?.getCandidate?.fullName}
            />
          </TabPane>

          <TabPane tabId="3">
            <ActionLogsAndNotes
              option={{
                candidateId: data?.getCandidate?.id
                  ? data?.getCandidate?.id
                  : '',
                candidateName: data?.getCandidate?.fullName
                  ? data?.getCandidate?.fullName
                  : '',
              }}
              type="Candidate"
              recordId={candidateId}
              viewDetail={false}
              isPrivatePermission={
                userRoles?.includes(EXECUTIVE_ROLE) ||
                userRoles?.includes(HR_ROLE) ||
                data?.getCandidate?.shareWith?.includes(user?.id)
              }
              updateNotesRef={updateNotesRef}
            />
          </TabPane>

          <TabPane tabId="4" className="tab-pane-padding">
            <CandidateOwnershipWriteup
              candidateId={candidateId}
              refetchCandidateWriteUp={refetchCandidateOwner}
              refetchCandidateDetails={getCandidateOwner}
            />
          </TabPane>

          <TabPane tabId="5" className="tab-pane-padding">
            <SubmissionHistoryCandidate
              candidateId={candidateId}
              location={location}
            />
          </TabPane>

          <TabPane tabId="6">
            <ReferenceListing
              candidateId={candidateId}
              candidate={data?.getCandidate}
              editReference={false}
            />
          </TabPane>

          <TabPane tabId="7">
            <Attachments companyId={candidateId} attachableType={'Candidate'} />
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <JobApplicationStatus candidate={data?.getCandidate} refetchCandidateOwner={() => getCandidateOwner()} />
          </TabPane>

          <TabPane tabId="10" className="tab-pane-padding">
            <EmploymentHistory
              candidateId={candidateId}
              addEmployment={false}
            />
          </TabPane>

          <TabPane tabId="11" className="tab-pane-padding">
            <CandidateEducation
              candidateId={candidateId}
              addEducation={false}
            />
          </TabPane>
        </TabContent>
      </div>

      <CandidateRecruiterModal
        isOpen={recruiterFolder}
        toggle={recruiterFolderToggle}
        recordToAdd={[data?.getCandidate?.id]}
        refetch={() => console.log('Refetching candidates')}
        clearRecord={() => console.log('Clearing record')}
      />

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={candidateId}
        reFetch={(type) => _getNotes(type)}
        type="Candidate"
        editData={noteData}
        refetchCandidate={refetch}
        resetField={setNoteData}
        isPrivateCandidate={data?.getCandidate?.isPrivateCandidate}
        setCompensationNote={setCompensationNote}
        updateNotesRef={updateNotesRef}
      />

      {jobApply && (
        <CandidateSubmitDrawer
          toggle={onApplyJob}
          isOpen={jobApply} AddNotesModal
          BackURL={`${VIEW_CANDIDATE_ROUTE}/${candidateId}`}
          candidate={data?.getCandidate}
          refetch={refetch}
        />
      )}

      {createApplicant && (
        <CandidateSubmitDrawer
          jobOrderId={jobOrderId}
          companyId={companyId}
          recruiterFolderSubmissionId={submissionId}
          recruiterFolderApplicantId={applicantId}
          refetchOwner={refetchCandidateWriteUp}
          // recruiterFolderRedirect={true}
          toggle={createApplicantToggle}
          isOpen={createApplicant}
          candidateId={candidateId}
          applyJob={true}
          candidate={data?.getCandidate}
          refetch={refetch}
        />
      )}

      <Modal isOpen={summaryModal} toggle={summaryModalToggle} id="noteModal">
        <ModalHeader toggle={summaryModalToggle}>Candidate Summary</ModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={createMarkup(summaryContent)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={summaryModalToggle}
            className="btn-primary"
          />
        </ModalFooter>
      </Modal>

      <div>
        <Modal isOpen={modal} toggle={toggleModal} className="candidateNotes">
          <ModalHeader>
            <h6>Candidate Notes</h6>

            <button onClick={toggleModal} type="button">
              {theme === 'light' ? (
                <img src={MODAL_CLOSE_ICON} alt="close" />
              ) : (
                <img src={MODAL_CLOSE_LIGHTICON} alt="close" />
              )}
            </button>
          </ModalHeader>

          <ModalBody>
            <CandidateNotesModal candidateId={match.params.id} />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(ViewCandidate);
