import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import GoBack from '../../components/GoBack';
import SocialMediaInfo from '../../components/SocialMediaInfo';
import CustomButton from '../../components/layout/CustomButton';
import Loader from '../../components/layout/Loader';
import { AppContext } from '../../context';
import {
  PARSE_RESUME_FILE_SIZE,
  UPDATE_CANDIDATE_ROUTE,
  states_candidate_constant,
} from '../../utils/constant';
import { resumeParse } from '../../utils/helper';
import { IComingState } from '../agreements/interfaces';
import AddressInfo from '../company/company-components/AddressInfo';
import '../company/company.scss';
import ContactPhone from '../contacts/contact-components/ContactPhone';
import CandidateBasicInfo from './candidate-components/CandidateBasicInfo';
import CandidateEmails from './candidate-components/CandidateEmails';
import DragDropFile from './candidate-components/DragDropFile';
import { CREATE_CANDIDATE } from './gql';

const CreateCandidate = () => {
  const history = useHistory();
  const location = useLocation<IComingState>();
  const { privateCandidate } = useContext(AppContext);
  const comingState = location.state;
  const [phoneErr, setPhoneErr] = useState('');
  const [toggleEmailOrLinkdin, setToggleEmailOrLinkdin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [candidateResume, setCandidateResume] = useState<any>();
  const [closeWidget, setCloseWidget] = useState(false);
  const [parsingLoading, setParsingLoading] = useState(false);
  const [verifyEmailBool, setVerifyEmailBool] = useState(false);

  // Function to set the verifyEmailBool state
  const setVerifyEmailStatus = (status: boolean) => {
    setVerifyEmailBool(status);
  };

  const toggleWidget = useCallback(() => {
    setCloseWidget(!closeWidget);
  }, [closeWidget]);

  const [_createCandidate, { loading }] = useMutation(CREATE_CANDIDATE);
  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: '',
      phoneType: '',
      isPrimary: true,
    },
  ]);

  const { errors, handleSubmit, watch, register, control, setValue, getValues, reset } = useForm({
    defaultValues: {
      emails: [{ email: '', isPrimary: true, emailId: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const handleRemove = (index: number, domainId: string) => {
    if (domainId) {
      return;
    } else {
      remove(index);
    }
  };

  const createCandidate = async (values: any) => {
    values.emails = values?.emails.filter((item: any) => item.email && item);
    if (!values?.emails?.length && !values?.linkedInUrl) {
      toast.error('Please provide a primary email or linkedin profile URL');
      return;
    }

    let phone = customPhoneInputs.filter((item: any) => item.phone && item);
    values.phones = phone;
    values.source = values.source?.value || '';
    values.country = values.countryCode;
    values.state = values.stateCode;
    values.creationSource = 'Manual';
    delete values?.countryCode;

    let res = await _createCandidate({
      variables: { CreateCandidateDto: { ...values } },
    });

    if (res?.data) {
      history.push(
        `${UPDATE_CANDIDATE_ROUTE}/${res?.data?.createCandidate?.id}`
      );
      toast.success('Candidate Successfully Created!');
      reset();
    }
  };


  const resumeUploadParse = useCallback(async () => {
    try {
      setParsingLoading(true);
      let file = candidateResume;
      let isPrivateCandidate = privateCandidate as boolean
      let response = await resumeParse(file, isPrivateCandidate);

      if (response) {
        if (response?.status === 200) {
          //upload Resume to private
          history.push(`${UPDATE_CANDIDATE_ROUTE}/${response?.candidate?.id}`);

          setErrorMessage('');
          toggleWidget();
          setCandidateResume(null);
          setParsingLoading(false);
          toast.success(response?.message);
        } else if (response?.status === 409) {
          history.push(`${UPDATE_CANDIDATE_ROUTE}/${response?.candidate?.id}`);
          setErrorMessage('');
          toggleWidget();
          setCandidateResume(null);
          setParsingLoading(false);
          toast.warn(response?.message);
        } else if (response?.status === 422) {
          history.push(`${UPDATE_CANDIDATE_ROUTE}/${response?.candidate?.candidateId}`);
          setErrorMessage('');
          toggleWidget();
          setCandidateResume(null);
          setParsingLoading(false);
          toast.warn(response?.message);
        }
        else {
          toast.warn(response?.message);
        }
      } else {
        toast.error(response?.message);
      }

      setParsingLoading(false);
    } catch (error: any) {
      setParsingLoading(false);
      toast.error(
        error?.message ? error?.message : 'Error while parsing resume!'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateResume, history, location?.search, toggleWidget]);

  useEffect(() => {
    if (candidateResume) {
      resumeUploadParse();
    }
  }, [candidateResume, resumeUploadParse]);

  const isFileImage = (file: File): boolean => {
    return file && file['type'].split('/')[0] !== 'image';
  };
  const dragDropFileHandle = (file) => {
    if (file) {
      setErrorMessage('');

      if (!isFileImage(file)) {
        return setErrorMessage("File should't be image!");
      }

      if (file.size > PARSE_RESUME_FILE_SIZE) {
        return setErrorMessage(
          'Max file size limit for creating a candidate by resume is 6MB'
        );
      }

      setCandidateResume(file);
    }
  };
  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage('');

      return;
    }
  }, [errorMessage]);

  if (parsingLoading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs customUploadHover">
      <ToastContainer autoClose={3000} />
      <GoBack url="/candidates" search={privateCandidate ? `${comingState?.searchParameters}&isPrivate=true}` : comingState?.searchParameters} />
      <div className="primaryHeading">
        <h5 className="m-0">Add Candidate</h5>
      </div>

      <h5
        style={{
          fontWeight: 'bold',
          fontSize: '12px',
          textTransform: 'capitalize',
          color: '#272727',
        }}
      >
        Drag Resume
      </h5>
      <div className={'revampContainer '}>
        <div style={{ position: 'relative' }}>
          <DragDropFile
            onUpload={(file) => {
              dragDropFileHandle(file);
            }}
          />
        </div>
      </div>

      <div className="revampContainer mt-4">
        <CandidateBasicInfo
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          control={control}
          editCandidate={false}
          watch={watch}
        />

        <div className="mt-2">
          <CandidateEmails
            getValues={getValues}
            setValue={setValue}
            append={append}
            control={control}
            fields={fields}
            register={register}
            errors={errors}
            remove={handleRemove}
            setToggleEmailOrLinkdin={setToggleEmailOrLinkdin}
            verifyEmailBool={verifyEmailBool} // Pass the state
            setVerifyEmailStatus={setVerifyEmailStatus} // Pass the function
          />
        </div>

        <div className="mt-4">
          <ContactPhone
            setCustomPhoneInputs={setCustomPhoneInputs}
            customPhoneInputs={customPhoneInputs}
            error={phoneErr}
            setErr={setPhoneErr}
            isRequired={false}
          />
        </div>

        <div className="mt-4">
          <h5>Address</h5>
          <AddressInfo
            register={register}
            watch={watch}
            errors={errors}
            headQatar={false}
            givenStates={states_candidate_constant}
            setValue={setValue}
          />
        </div>

        <div className="mt-3">
          <h5>Social Media</h5>
          <SocialMediaInfo
            setValue={setValue}
            register={register}
            errors={errors}
            isLinkedinRequired={!toggleEmailOrLinkdin}
            linkedinErr="LinkedIn URL would be required if candidate does not have an email"
          />
        </div>

        <div className="pt-2 text-right">
          <CustomButton
            buttonText="Add Candidate"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            loading={loading}
            disabled={loading || verifyEmailBool}
            buttonClick={handleSubmit(createCandidate)}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateCandidate);
